import { Form, Checkbox, Space } from 'antd';

import { FORM_RULES } from '../../utils/config';

import styles from './Step2.module.scss';

const Step2 = ({ disabled }) => {
    const options = [
        { label: 'sporurile cu caracter permanent care fac parte din baza de calcul a pensiei', value: '1' },
        { label: 'grupă muncă', value: '2' },
        { label: 'ore lucrate în timpul nopții', value: '3' },
        { label: 'salariu lunar brut', value: '4' }
    ];

    return (
        <div className={styles.wrapper} id='step_2'>
            <h2>Pasul 2. Selectarea adeverințelor solicitate</h2>
            <p>Specificați tipul de adeverințe solicitate.</p>

            <Form.Item
                name={['step2', 'tip_adeverinta']}
                label='Solicit eliberarea adeverințelor privind:'
                rules={[FORM_RULES.requiredStep2]}
            >
                <Checkbox.Group disabled={disabled}>
                    <Space direction='vertical'>
                        {options.map((option, index) => (
                            <Checkbox value={option.value} key={index}>
                                {option.label}
                            </Checkbox>
                        ))}
                    </Space>
                </Checkbox.Group>
            </Form.Item>
        </div>
    );
};

export default Step2;
