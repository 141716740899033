import { Form, Input } from 'antd';

import { FORM_RULES } from '../../utils/config';

import styles from './Step3.module.scss';

const Step3 = ({ disabled }) => {
    return (
        <div className={styles.wrapper} id='step_3'>
            <h2>Pasul 3. Selectarea perioadei lucrate</h2>
            <p>Introduceți societatea angajatoare și perioada lucrată.</p>

            <Form.Item name={['step3', 'societate']} label='Societatea angajatoare' rules={[FORM_RULES.required]}>
                <Input placeholder='ex: Automobile Dacia' disabled={disabled} />
            </Form.Item>

            <Form.Item label='Perioada lucrată:' required>
                <Form.Item
                    className={styles.from}
                    label='De la:'
                    name={['step3', 'startdate']}
                    rules={[FORM_RULES.required, FORM_RULES.date]}
                >
                    <Input placeholder='ex. 2020-11-20' disabled={disabled} />
                </Form.Item>
                <Form.Item
                    className={styles.to}
                    label='Până la:'
                    name={['step3', 'enddate']}
                    rules={[FORM_RULES.required, FORM_RULES.date]}
                >
                    <Input placeholder='ex. 2020-11-20' disabled={disabled} />
                </Form.Item>
            </Form.Item>
        </div>
    );
};

export default Step3;
