import { Form, Radio, Space } from 'antd';

import { FORM_RULES } from '../../utils/config';

import styles from './Step4.module.scss';

const Step4 = ({ disabled }) => {
    const options = [
        {
            label: 'întocmirea dosarului de pensionare',
            value: 'întocmirea dosarului de pensionare'
        },
        {
            label: 'recalcularea pensiei',
            value: 'recalcularea pensiei'
        },
        {
            label: 'pensie de urmaș',
            value: 'pensie de urmaș'
        },
        {
            label: 'instanțe',
            value: 'instanțe'
        }
    ];
    return (
        <div className={styles.wrapper} id='step_4'>
            <h2>Pasul 4. Selectarea motivului</h2>
            <p>Specificați motivul pentru care solicitați eliberarea adeverințelor menționate mai sus.</p>

            <Form.Item
                name={['step4', 'scop_adeverinta']}
                label='Solicit aceste adeverințe pentru:'
                rules={[FORM_RULES.requiredStep4]}
            >
                <Radio.Group disabled={disabled}>
                    <Space direction='vertical'>
                        {options.map((option, index) => (
                            <Radio value={option.value} key={index}>
                                {option.label}
                            </Radio>
                        ))}
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};

export default Step4;
