import { useState } from 'react';
import { useRouter } from 'next/router';
import { Steps, Button, Form as AntdForm } from 'antd';

import Step1 from '../Step1';
import Step2 from '../Step2';
import Step3 from '../Step3';
import Step4 from '../Step4';
import Step5 from '../Step5';
import StepContainer from '../../containers/StepContainer';

import APP_CONFIG from '../../utils/config';
import { scrollParentBy, transformData } from '../../utils/helpers';
import axiosInstance, { MULTIPART_FORM_DATA } from '../../utils/axios';

import styles from './Form.module.scss';

const { Step } = Steps;

const getStepState = (index, activeStep) => {
    return activeStep === index ? 'enabled' : activeStep >= index ? 'completed' : 'disabled';
};

const Form = () => {
    const router = useRouter();
    const [form] = AntdForm.useForm();

    const [loading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [formError, setFormError] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);

    const next = async () => {
        const allFields = form.getFieldsValue();
        const activeStepFields = Object.keys(allFields[`step${activeStep + 1}`]);

        try {
            const validationResponse = await form.validateFields(
                activeStepFields.map((item) => [`step${activeStep + 1}`, item])
            );
            console.log(`validate step ${activeStep + 1}`, validationResponse);

            const nextStep = document.getElementById(`step_${activeStep + 2}`)?.parentElement;

            scrollParentBy(nextStep?.offsetTop);

            setActiveStep(activeStep + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const onFinish = async (values) => {
        setErrorMessages(null);
        console.log('raw values:', values);
        setLoading(true);

        const formData = transformData(values);

        try {
            const response = await axiosInstance.post('/addCertificate', formData, MULTIPART_FORM_DATA);
            console.log(response);

            if (response.data.error) {
                setFormError(true);
                setLoading(false);
                try {
                    const keys = Object.keys(response.data.errorDetails);
                    setErrorMessages(keys.map((key) => response.data.errorDetails[key]));
                } catch (e) {
                    console.log(e);
                }
                return;
            }

            setLoading(false);
            router.replace('/congrats');
            // setParentHeight();
        } catch (err) {
            setFormError(true);
            setLoading(false);
            console.log(err);
        }
    };

    const mockForm = () => {
        const fieldsName = form.getFieldsValue().step1;
        const mockFields = {
            ...Object.keys(fieldsName).reduce((acc, item) => ({ ...acc, [item]: 'asdfadsf' }), {}),
            angajat: 'angajat activ',
            cnp: '1971113225892',
            email: 'robert.parasca@grapefruit.ro',
            bday: '1997-11-13',
            ctel: '0734362341'
        };
        form.setFieldsValue({ step1: mockFields });
    };

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.navigationWrapper}>
                <h2>Solicită adeverințe</h2>
                <p>Solicită adeverințe pentru pensionare/ recalculare pensie.</p>
                <Steps
                    current={activeStep}
                    direction='vertical'
                    className={styles.formWrapper}
                    progressDot={() => null}
                >
                    {APP_CONFIG.formSteps.map((step, key) => (
                        <Step key={key} title={step.title} description={step.description} />
                    ))}
                </Steps>
            </div>
            <div className={styles.contentWrapper}>
                <AntdForm
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                    validateTrigger='onBlur'
                    initialValues={{ step1: { angajat: 'angajat activ' } }}
                >
                    <StepContainer
                        callback={next}
                        shouldBtnAppear={activeStep === 0}
                        state={getStepState(0, activeStep)}
                    >
                        {/* <button onClick={mockForm}>mock</button> */}
                        <Step1 />
                    </StepContainer>

                    <StepContainer
                        callback={next}
                        shouldBtnAppear={activeStep === 1}
                        state={getStepState(1, activeStep)}
                    >
                        <Step2 disabled={activeStep < 1} />
                    </StepContainer>

                    <StepContainer
                        callback={next}
                        shouldBtnAppear={activeStep === 2}
                        state={getStepState(2, activeStep)}
                    >
                        <Step3 disabled={activeStep < 2} />
                    </StepContainer>

                    <StepContainer
                        callback={next}
                        shouldBtnAppear={activeStep === 3}
                        state={getStepState(3, activeStep)}
                    >
                        <Step4 disabled={activeStep < 3} />
                    </StepContainer>

                    <StepContainer callback={next} shouldBtnAppear={false} state={getStepState(4, activeStep)}>
                        <Step5 disabled={activeStep < 4} form={form} />
                        <div className={styles.sendButton}>
                            <Button
                                loading={loading}
                                type='primary'
                                htmlType='submit'
                                size='large'
                                disabled={activeStep < 4}
                            >
                                Expediază solicitarea
                            </Button>
                        </div>
                    </StepContainer>
                </AntdForm>

                {formError ? <div className={styles.error}>A apărut o eroare. Te rog incearcă din nou.</div> : null}
                {
                    errorMessages ? (
                        <div className={styles.error}>
                            <ul>
                                {errorMessages.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </div>
                    ) : null
                }
                <div className={styles.guide}>
                    <h2>
                        Consultaţi{' '}
                        <a
                            href='https://cdn.group.renault.com/dac/ro/despre-marca/Ghid_de_utilizare_Adeverinte_online_Dacia.pdf'
                            target='_blank'
                            rel='noreferrer'
                        >
                            Ghid_de_utilizare_Adeverinte_online.pdf
                        </a>
                    </h2>

                    <p>
                        Întâmpinaţi probleme tehnice la crearea sau expedierea solicitării? Trimiteţi-ne detalii privind
                        problema întalnită la adresa:{' '}
                        <a href='mailto:suport.tehnic@daciagroup.com'>suport.tehnic@daciagroup.com</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Form;

/**
 *
 * Left todos:
 * - scroll animation
 * - error tooltip
 * - test integration on dacia
 * - partea de jos: "Consultați ghidul bla, bla" e parte de la noi din app?
 * - UI congrats + confirm pages
 *
 * Left todos II:
 * - test integration on dacia
 * - partea de jos: "Consultați ghidul bla, bla"
 * - UI confirm page. URL în dacia.ro + pass param
 * - IE?
 */
