import { Button } from 'antd';

import classnames from 'classnames';

import styles from './StepContainer.module.scss';

const StepContainer = ({ children, state = 'enabled', callback, shouldBtnAppear }) => {
    return (
        <div className={classnames(styles.wrapper, styles[state])}>
            {children}

            {shouldBtnAppear ? (
                <Button
                    type='primary'
                    shape='circle'
                    className={styles.nextBtn}
                    onClick={callback}
                    icon={<img src='/images/arrow.svg' alt='arrow' width='28' height='28' />}
                    size='large'
                ></Button>
            ) : null}
        </div>
    );
};

export default StepContainer;
