import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form, Upload, Button, Checkbox } from 'antd';

import { getFileLimitByType } from '../../utils/helpers';
import APP_CONFIG, { FORM_RULES } from '../../utils/config';

import styles from './Step5.module.scss';

const Step5 = ({ disabled, form }) => {
    const [errorFileUpload, setErrorFileUpload] = useState({
        [APP_CONFIG.step5FileTypes.CI]: {
            size: false,
            type: false,
            filename: ''
        },
        [APP_CONFIG.step5FileTypes.CM]: {
            size: false,
            type: false,
            filename: ''
        }
    });

    const beforeUpload = async (file, type) => {
        const fileId = type === APP_CONFIG.step5FileTypes.CI ? 'file1' : 'file2';
        console.log(file, type);
        // const isValid = APP_CONFIG.step5FileTypes.allowedMimeTypes.includes(file.type);
        const isValid = true;

        const limit = getFileLimitByType(type);
        const isLimited = file.size / 1024 / 1024 < limit;

        if (!isValid || !isLimited) {
            const updated = {
                ...errorFileUpload,
                [type]: {
                    size: !isLimited ? true : errorFileUpload[type].size,
                    type: !isValid ? true : errorFileUpload[type].type,
                    filename: file.name
                }
            };
            setErrorFileUpload(updated);
            try {
                await form.validateFields([
                    ['step5', 'file1'],
                    ['step5', 'file2']
                ]);
            } catch (e) {
                console.log(e);
            }
        } else {
            const updated = {
                ...errorFileUpload,
                [type]: {
                    size: false,
                    type: false,
                    filename: ''
                }
            };
            setErrorFileUpload(updated);
        }

        setTimeout(() => {
            const error = document.querySelector(
                `#${fileId} .ant-row .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-connected`
            );

            const uploadedFiles = form.getFieldsValue([['step5', fileId]]);

            uploadedFiles?.step5[fileId]?.length && error?.setAttribute('style', 'bottom: 80px');
        }, 100);

        return isValid && isLimited ? false : Upload.LIST_IGNORE;
    };

    const onChange = (info, type) => {
        const fileId = type === APP_CONFIG.step5FileTypes.CI ? 'file1' : 'file2';

        if (info.fileList) {
            setTimeout(() => {
                const error = document.querySelector(
                    `#${fileId} .ant-row .ant-form-item-control .ant-form-item-explain.ant-form-item-explain-connected`
                );

                error?.removeAttribute('style');
            }, 100);
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const options = [
        {
            label: 'Am luat la cunoștință că documentele pe care le atașez prezentei solicitări (copia cărții de identitate și copia cărții de muncă) rămân la dispoziția angajatorului.',
            value: '1'
        },
        {
            label: 'Sunt de acord cu prelucrarea datelor cu caracter personal în condițiile menționate în Notificarea de Confidențialitate, pe care am citit-o aici și am înțeles-o înainte de a-mi exprima acordul.',
            value: '2'
        }
    ];

    return (
        <div className={styles.wrapper} id='step_5'>
            <h2>Pasul 5. Atașarea documetelor necesare</h2>
            <p>
                Atașați o copie după Cartea de Identitate și Carnetul de muncă. Sunt acceptate fișiere cu extensia jpg,
                jpeg, png, zip, rar și pdf.
            </p>
            <div className={styles.uploads}>
                <div id='file1'>
                    <h6>Atașează copie după Cartea de Identitate</h6>
                    <Form.Item
                        name={['step5', 'file1']}
                        validateTrigger='onChange'
                        valuePropName='fileList'
                        getValueFromEvent={normFile}
                        label='Dimensiunea fișierului nu trebuie să depășească 2 MB'
                        rules={[
                            FORM_RULES.requiredStep5,
                            FORM_RULES.file(errorFileUpload, APP_CONFIG.step5FileTypes.CI, 'size'),
                            FORM_RULES.file(errorFileUpload, APP_CONFIG.step5FileTypes.CI, 'type')
                        ]}
                    >
                        <Upload
                            beforeUpload={(file) => beforeUpload(file, APP_CONFIG.step5FileTypes.CI)}
                            onChange={(_) => onChange(_, APP_CONFIG.step5FileTypes.CI)}
                            disabled={disabled}
                            maxCount={1}
                            accept={APP_CONFIG.step5FileTypes.allowedMimeTypes}
                        >
                            <Button type='primary' icon={<UploadOutlined />} disabled={disabled}>
                                Încarcă
                            </Button>
                        </Upload>
                    </Form.Item>
                </div>
                <div id='file2'>
                    <h6>Atașează copie după Cartea de Muncă</h6>
                    <Form.Item
                        name={['step5', 'file2']}
                        validateTrigger='onChange'
                        valuePropName='fileList'
                        getValueFromEvent={normFile}
                        label='Dimensiunea fișierului nu trebuie să depășească 10 MB'
                        rules={[
                            FORM_RULES.requiredStep5,
                            FORM_RULES.file(errorFileUpload, APP_CONFIG.step5FileTypes.CM, 'size'),
                            FORM_RULES.file(errorFileUpload, APP_CONFIG.step5FileTypes.CM, 'type')
                        ]}
                    >
                        <Upload
                            beforeUpload={(file) => beforeUpload(file, APP_CONFIG.step5FileTypes.CM)}
                            onChange={(_) => onChange(_, APP_CONFIG.step5FileTypes.CM)}
                            disabled={disabled}
                            maxCount={1}
                            accept={APP_CONFIG.step5FileTypes.allowedMimeTypes}
                        >
                            <Button type='primary' icon={<UploadOutlined />} disabled={disabled}>
                                Încarcă
                            </Button>
                        </Upload>
                    </Form.Item>
                </div>
            </div>

            <div className={styles.gdpr}>
                {options.map((option, index) => (
                    <Form.Item
                        name={['step5', `agree${index}`]}
                        valuePropName='checked'
                        validateTrigger='onChange'
                        rules={[
                            FORM_RULES.required,
                            () => ({
                                validator(_, value) {
                                    if (value === true) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(APP_CONFIG.formErrorMessages.required));
                                }
                            })
                        ]}
                        key={index}
                    >
                        <Checkbox disabled={disabled}>{option.label}</Checkbox>
                    </Form.Item>
                ))}
            </div>
        </div>
    );
};

export default Step5;
