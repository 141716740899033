import { Select, Radio, Form, Input } from 'antd';

import APP_CONFIG from '../../utils/config';
import { FORM_RULES } from '../../utils/config';
import { validateCNP } from '../../utils/helpers';

import styles from './Step1.module.scss';

const { Option } = Select;

const Step1 = () => {
    return (
        <div className={styles.wrapper} id='step_1'>
            <h2>Pasul 1. Introducerea datelor cu caracter personal</h2>
            <p>
                Pentru stabilirea identității dumneavoastră introduceți datele cu caracter personal din Cartea de
                Identitate.
            </p>

            <div className={styles.row}>
                <div className={styles.col}>
                    <Form.Item label='Nume Prenume' name={['step1', 'cname']} rules={[FORM_RULES.required]}>
                        <Input placeholder='ex. Popescu Ion' />
                    </Form.Item>

                    <Form.Item label='Prenumele tatălui' name={['step1', 'cname_father']} rules={[FORM_RULES.required]}>
                        <Input placeholder='ex. Victor' />
                    </Form.Item>

                    <Form.Item label='Prenumele mamei' name={['step1', 'cname_mother']} rules={[FORM_RULES.required]}>
                        <Input placeholder='ex. Maria' />
                    </Form.Item>

                    <Form.Item
                        label='Număr de telefon'
                        name={['step1', 'ctel']}
                        rules={[
                            FORM_RULES.required,
                            () => ({
                                validator(_, value) {
                                    if (FORM_RULES.ctel.pattern.test(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(APP_CONFIG.formErrorMessages.ctel));
                                }
                            })
                        ]}
                    >
                        <Input placeholder='ex. 0734362341' />
                    </Form.Item>

                    <Form.Item
                        label='E-Mail (required)'
                        name={['step1', 'email']}
                        rules={[
                            FORM_RULES.required,
                            () => ({
                                validator(_, value) {
                                    if (FORM_RULES.email.pattern.test(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(APP_CONFIG.formErrorMessages.email));
                                }
                            })
                        ]}
                    >
                        <Input placeholder='ex. Popescu.ion@gmail.com' />
                    </Form.Item>
                    <Form.Item label='Menționez că, sunt:' name={['step1', 'angajat']} rules={[FORM_RULES.required]}>
                        <Radio.Group buttonStyle='solid' size='large'>
                            <Radio.Button value='angajat activ'>ANGAJAT ACTIV</Radio.Button>
                            <Radio.Button value='fost angajat'>FOST ANGAJAT</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>

                <div className={styles.col}>
                    <Form.Item
                        className={styles.errorMessageDate}
                        label='Data nașterii'
                        name={['step1', 'bday']}
                        rules={[FORM_RULES.required, FORM_RULES.date]}
                    >
                        <Input placeholder='ex. 2020-11-20' />
                    </Form.Item>

                    <Form.Item label='Locul nașterii' required>
                        <Form.Item
                            name={['step1', 'bplace']}
                            rules={[FORM_RULES.required]}
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: '0' }}
                        >
                            <Input placeholder='Județ' />
                        </Form.Item>
                        <Form.Item
                            name={['step1', 'bplace_city']}
                            style={{ display: 'inline-block', width: '50%', margin: '0 0 0 8px' }}
                        >
                            <Input placeholder='Oraș' />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item label='Domiciliu' required>
                        <Form.Item
                            name={['step1', 'judet']}
                            rules={[FORM_RULES.required]}
                            style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginBottom: '0' }}
                        >
                            <Select size='large' placeholder='Județ' showSearch>
                                {APP_CONFIG.counties.map((x, i) => (
                                    <Option value={x} key={i}>
                                        {x}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={['step1', 'domiciliu_city']}
                            rules={[FORM_RULES.required]}
                            style={{ display: 'inline-block', width: '50%', margin: '0 0 0 8px' }}
                        >
                            <Input placeholder='Oraș' />
                        </Form.Item>
                    </Form.Item>

                    <div className={styles.row}>
                        <Form.Item
                            label='Strada'
                            name={['step1', 'cstreet']}
                            rules={[FORM_RULES.required]}
                            style={{ width: '100%', marginRight: '8px' }}
                        >
                            <Input placeholder='ex. Calea București' />
                        </Form.Item>
                        <Form.Item label='Număr' name={['step1', 'nrstreet']} rules={[FORM_RULES.required]}>
                            <Input />
                        </Form.Item>
                    </div>

                    <div className={styles.row}>
                        <Form.Item label='Bloc' name={['step1', 'bloc']} style={{ width: '100%', marginRight: '8px' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Scara' name={['step1', 'scara']} style={{ marginRight: '8px' }}>
                            <Input />
                        </Form.Item>
                        <Form.Item label='Apartament' name={['step1', 'apartament']}>
                            <Input />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label='CNP'
                        name={['step1', 'cnp']}
                        rules={[
                            FORM_RULES.required,
                            () => ({
                                validator(_, value) {
                                    if (validateCNP(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error(APP_CONFIG.formErrorMessages.CNP));
                                }
                            })
                        ]}
                    >
                        <Input placeholder='ex. 123456789101' />
                    </Form.Item>
                </div>
            </div>
        </div>
    );
};

export default Step1;
